import { GeocodeResult } from 'use-places-autocomplete'

/**
 * Represents a formatted address object.
 */
const createEmptyFormattedAddress = () => {
  return {
    city: '',
    country: '',
    neighborhood: '',
    number: '',
    place_id: '',
    postalCode: '',
    state: '',
    street: ''
  }
}

/**
 * Checks if a given filter exists in the types array.
 * @param types - The array of types to search in.
 * @param filter - The filter to look for in the types array.
 * @returns True if the filter is found, false otherwise.
 */
const findTypes = (types: string[], filter: string): boolean => {
  return types.indexOf(filter) > -1
}

/**
 * Formats the given address data into a standardized format.
 * @param addressData - The address data to format.
 * @returns The formatted address object.
 */
const addressFormat = (
  addressData: GeocodeResult[]
): ReturnType<typeof createEmptyFormattedAddress> => {
  const formatted = createEmptyFormattedAddress()

  const findPlaceId = addressData.find((addressDataItem) => {
    return addressDataItem.place_id
  })
  if (findPlaceId) {
    formatted.place_id = findPlaceId.place_id
  }

  addressData.forEach((addresDataItem) => {
    addresDataItem?.address_components?.forEach((addressParts) => {
      if (findTypes(addressParts.types, 'street_number') && !formatted.number) {
        formatted.number = addressParts.long_name
      }

      if (findTypes(addressParts.types, 'route') && !formatted.street) {
        formatted.street = addressParts.short_name
      }

      if (
        (findTypes(addressParts.types, 'locality') ||
          findTypes(addressParts.types, 'administrative_area_level_3')) &&
        !formatted.neighborhood
      ) {
        formatted.neighborhood = addressParts.long_name
      }

      if (
        findTypes(addressParts.types, 'administrative_area_level_1') &&
        !formatted.state
      ) {
        formatted.state = addressParts.long_name
      }

      if (
        findTypes(addressParts.types, 'administrative_area_level_2') &&
        !formatted.city
      ) {
        formatted.city = addressParts.long_name
      }

      if (findTypes(addressParts.types, 'country') && !formatted.country) {
        formatted.country = 'CHL'
      }

      if (
        findTypes(addressParts.types, 'postal_code') &&
        !formatted.postalCode
      ) {
        formatted.postalCode = addressParts.long_name
      }
    })
  })

  return formatted
}

export { addressFormat }
