import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Container,
  HeaderCart,
  IndexingMembershipModal,
  SomethingIsWrongModal,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  Cookies,
  getContentfulData,
  IEntriesProps,
  IPostValidateSC,
  IResponse,
  isValidArrayWithData,
  postValidateSc,
  trigger,
  useAbandonedCart,
  useContentful,
  useEvents,
  useMobile,
  useNewCartUnimarc,
  useOrderForm,
  useSafetyValidationSL,
  useSession,
  useUpdateAddressFromLocal,
  useUpdateQuantityQuickly
} from '@smu-chile/pkg-unimarc-hooks'
import {
  BigScreen,
  SmallScreen,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import { StoreLocatorComponent } from 'components/StoreLocator'
import { DeliverySelectorTypeComponent } from 'components/Body/DeliverySelectorType'
import { ModalMessage, ModalMessageItem } from 'components/ModalMessage'
import { ValidateSlaWrapper } from 'components/ValidateSlaWrapper'
import Link from 'next/link'
import {
  SOMETHING_IS_WRONG_MODAL_IMAGE,
  CONFIRM_ADDRESS_MODAL_IMAGE,
  HEADER_TITLE
} from 'shared/utils/constants'
import { Footer } from 'components/Footer'
import { MembershipWrapper } from 'components/MembershipWrapper'
import { AuthWrapper } from 'components/AuthWrapper'

interface LayoutProps {
  children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps): React.ReactElement => {
  const [isVerticalScrollbarActive, setIsVerticalScrollbarActive] =
    useState(false)
  const { data, isLoading } = useOrderForm()
  const router = useRouter()
  const [orderFormExist, setOrderFormExist] = useState(false)
  const [failedData, setFailedData] = useState(null)
  const { data: newCart, refetch: refetchCart } = useNewCartUnimarc()
  const [productNote, setProductNote] = useState({
    item: {} as ModalMessageItem,
    open: false
  })
  const [openModalSla, setOpenModalSla] = useState(false)
  const [openSmIsWrongModal, setOpenSmIsWrongModal] = useState(false)
  const [errorCodeResponse, setErrorCodeResponse] = useState('')
  const [headerTitle, setHeaderTitle] = useState(HEADER_TITLE[0])
  const { isLoggedIn } = useSession()
  const { handleMutate } = useUpdateQuantityQuickly()
  const [validateSlaResponse, setValidateSlaResponse] =
    useState<IResponse<IPostValidateSC>>()
  const [footerData, setFooterData] = useState<IEntriesProps>()
  const [showIndexingMemerbershipModal, setShowIndexingMemerbershipModal] =
    useState(false)
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const homeUrl = process.env.NEXT_PUBLIC_HOMEURL

  // abandonedCart flow
  useAbandonedCart()
  // validate ORT
  const { codeWasValidated, generateOTP, safetyValidationProps } =
    useSafetyValidationSL({
      email: data?.data?.clientProfileData?.email,
      firstName: data?.data?.clientProfileData?.firstName,
      lastName: data?.data?.clientProfileData?.lastName,
      phone: data?.data?.clientProfileData?.phone
    })
  // Update user address if has one saved in local
  useUpdateAddressFromLocal({
    data,
    router,
    isLoading,
    isLoggedIn,
    codeWasValidated,
    generateOTP
  })

  useEvents({
    eventType: 'addToCart',
    callBack: ({ detail: { items } }) => {
      handleMutate(items)
    }
  })

  useEvents({
    eventType: 'productNote',
    callBack: ({ detail: { item, show } }) => {
      setProductNote({
        ...productNote,
        item,
        open: show
      })
    }
  })

  useEvents({
    eventType: 'somethingIsWrong',
    callBack: ({ detail: { show, errorCode } }) => {
      setErrorCodeResponse(errorCode)
      setOpenSmIsWrongModal(!!show)
    }
  })

  useEvents({
    eventType: 'showMoreIndexingMemerbership',
    callBack: ({ detail: { show } }) => {
      setShowIndexingMemerbershipModal(show)
    }
  })

  const somethingIsWrongModal = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-modal-sm-is-wrong',
    options: {
      'sys.id': SOMETHING_IS_WRONG_MODAL_IMAGE[0]
    },
    type: 'assets'
  })

  const confirmAddressModal = useContentful({
    id_contentful:
      'contentful-builtin-asset-content-type-modal-confirm-address',
    options: {
      'sys.id': CONFIRM_ADDRESS_MODAL_IMAGE[0]
    },
    type: 'assets'
  })

  const somethingIsWrongModalImage =
    somethingIsWrongModal.data?.['items']?.[0]?.fields?.file?.url || ''

  const confirmAddressModalImage =
    confirmAddressModal.data?.['items']?.[0]?.fields?.file?.url || ''

  const handleOnCloseSmIsWrong = () => {
    setOpenSmIsWrongModal(false)
    setOpenModalSla(false)
    trigger({
      eventType: 'storeLocator',
      data: { show: true, sameAddressOnClose: true }
    })
  }

  const handleCloseIndexingModal = () => {
    refetchCart()
    trigger({
      eventType: 'showMoreIndexingMemerbership',
      data: { show: false }
    })
  }

  const onClickBack = () => {
    if (router.pathname === '/') {
      router.push(process.env.NEXT_PUBLIC_HOMEURL)
    } else {
      router.push('/')
    }
  }

  const getFooterData = async () => {
    const footer = await getContentfulData({
      id_contentful: 'contentful_minifooter',
      options: {
        content_type: 'unimarcWebTopFooter',
        'fields.label[in]': 'Mini Footer (Desktop)',
        'fields.platform': 'Web Unimarc eComm'
      }
    })
    setFooterData(footer)
    return footer
  }

  // validate if the orderform have failed or info properties with data
  // and show the noStockToast
  useEffect(() => {
    if (isValidArrayWithData(newCart?.removedProducts)) {
      setFailedData(newCart)
    }
  }, [newCart])

  useEffect(() => {
    // trigger StoreLocator and exit early if no address is present
    if (!data?.data?.selectedAddresses?.addressType) {
      trigger({
        eventType: 'storeLocator',
        data: { show: true, dontAllowCloseSL: true }
      })
    }
  }, [data])

  useEffect(() => {
    if (isLoading) return

    if (isLoggedIn) {
      setOrderFormExist(true)
    }
  }, [isLoading, isLoggedIn])

  // The role of this function is to search the "addTocart" cookie and
  // send his data to the backend for add of the orderForm
  // and the same way validate if the use mobile and force reload when a change to a page with arrow back
  useEffect(() => {
    const checkAddToCartPending = () => {
      if (!isLoggedIn || failedData) return
      const recoverAddToCartPending = JSON.parse(
        Cookies.get('addToCart') || '[]'
      )
      if (isValidArrayWithData(recoverAddToCartPending)) {
        handleMutate(recoverAddToCartPending)
      }
    }
    checkAddToCartPending()
  }, [isLoggedIn, failedData])

  // Validate sales channel for SLA NULL flow
  useEffect(() => {
    const validateSC = async () => {
      if (!isLoading) {
        // use BE service to validate if user have a different sales channel in another session
        const validateSla = await postValidateSc({
          geoCoordinates: data?.data?.selectedAddresses?.geoCoordinates
        })
        setValidateSlaResponse(validateSla.data)
        setOpenModalSla(validateSla?.status === 409)
      }
    }
    if (isValidArrayWithData(data?.data?.selectedAddresses?.geoCoordinates)) {
      validateSC()
    }
    return () => {
      setOpenModalSla(false)
    }
  }, [data?.data?.selectedAddresses, isLoading])

  useEffect(() => {
    if (router.pathname === '/MyShipments') {
      return setHeaderTitle(HEADER_TITLE[1])
    }
    return setHeaderTitle(HEADER_TITLE[0])
  }, [router.pathname])

  useEffect(() => {
    const handleScrollCheck = () => {
      const hasVerticalScrollbar =
        document?.documentElement?.scrollHeight > window?.innerHeight
      setIsVerticalScrollbarActive(hasVerticalScrollbar)
    }

    // Call handleScrollCheck once on page load
    handleScrollCheck()

    // Listening to the window resizing event
    window?.addEventListener('resize', handleScrollCheck)

    // Remove the event listener when the component is disassembled
    return () => {
      window?.removeEventListener('resize', handleScrollCheck)
    }
  }, [router?.pathname])

  useEffect(() => {
    getFooterData()
  }, [])

  return (
    <>
      <AuthWrapper />
      <MembershipWrapper />
      {showIndexingMemerbershipModal && (
        <IndexingMembershipModal
          handleClose={handleCloseIndexingModal}
          isMobile={isMobile}
        />
      )}
      {openSmIsWrongModal && (
        <SomethingIsWrongModal
          errorCode={errorCodeResponse}
          isMobile={isMobile}
          isOpen={openSmIsWrongModal}
          onClose={handleOnCloseSmIsWrong}
          somethingIsWrongImage={somethingIsWrongModalImage}
        />
      )}
      <BigScreen>
        <>
          <HeaderCart
            isMobile={isMobile}
            isVerticalScrollbarActive={isVerticalScrollbarActive}
            linkWrapper={Link}
            logo='LogoHeaderUnimarcCL'
            logoId={getBemId('headerLogo', 'image')}
            logoUrl={homeUrl}
            onClickBack={onClickBack}
            title={headerTitle}
            titleId={getBemId(
              headerTitle === HEADER_TITLE[0] ? 'headerCart' : 'headerShipment',
              'title'
            )}
          />
          <Spacer.Horizontal customSize={32} />
        </>
      </BigScreen>
      <ValidateSlaWrapper
        openModalSla={openModalSla}
        orderFormData={data?.data}
        setOpenModalSla={setOpenModalSla}
        validateSlaResponse={validateSlaResponse?.data}
      />
      <Container isWrap>{children}</Container>
      <BigScreen>
        <>
          {productNote.open && <ModalMessage item={productNote.item} />}
          {footerData && <Footer data={footerData} />}
        </>
      </BigScreen>
      <SmallScreen>
        {productNote.open && <ModalMessage item={productNote.item} />}
      </SmallScreen>
      {orderFormExist && isLoggedIn && (
        <>
          <StoreLocatorComponent
            confirmAddressModalImage={confirmAddressModalImage}
            generateOTP={generateOTP}
            safetyValidationProps={safetyValidationProps}
          />
          <DeliverySelectorTypeComponent
            confirmAddressModalImage={confirmAddressModalImage}
          />
        </>
      )}
    </>
  )
}
