import { useRouter } from 'next/router'
import { LoginModalBrowse } from 'components/LoginModalBrowse'
import {
  useSession,
  useUserWithMembership,
  useCheckUserData
} from '@smu-chile/pkg-unimarc-hooks'
import { useEffect, useState } from 'react'

export const AuthWrapper = () => {
  const router = useRouter()
  const session = useSession()
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const [shouldCheckUser, setShouldCheckUser] = useState(false)
  const [loginModalStep, setLoginModalStep] = useState<string>(undefined)
  const [isUserMissingData, setIsUserMissingData] = useState(false)

  const { data: dataMembership, isLoading: isLoadingMembership } =
    useUserWithMembership()

  /**
    Check if user is still missing data after closing login
   */
  useCheckUserData(shouldCheckUser, isUserMissingData, setIsUserMissingData)

  const handleLoginModalClose = () => {
    setShouldCheckUser(true)
    if (session.isLoggedIn) {
      const { pathname, query } = router
      delete router.query.login
      router.replace({ pathname, query }, undefined, { shallow: true })
      setIsLoggedIn(true)
    } else {
      router.replace(process.env.NEXT_PUBLIC_HOMEURL || '/')
    }
  }

  const shouldLoginModalBeOpen = () => {
    return !isLoggedIn || isUserMissingData
  }

  useEffect(() => {
    if (!session?.isLoggedIn && !session?.isLoading) {
      setIsLoggedIn(false)
    } else {
      setIsLoggedIn(true)
    }
  }, [session?.isLoggedIn, session?.isLoading])

  useEffect(() => {
    setLoginModalStep(isUserMissingData ? 'missingUserData' : 'login')
  }, [isUserMissingData])

  useEffect(() => {
    if (dataMembership?.code === 'USR-23') {
      setIsUserMissingData(true)
    }
  }, [dataMembership, isLoadingMembership])

  return (
    <LoginModalBrowse
      defaultFormStep={loginModalStep}
      isOpen={shouldLoginModalBeOpen()}
      onClose={handleLoginModalClose}
      router={router}
    />
  )
}
